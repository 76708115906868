import { render, staticRenderFns } from "./paypalPaymentSuccess.vue?vue&type=template&id=52c6a840&scoped=true&"
import script from "./paypalPaymentSuccess.vue?vue&type=script&lang=js&"
export * from "./paypalPaymentSuccess.vue?vue&type=script&lang=js&"
import style0 from "./paypalPaymentSuccess.vue?vue&type=style&index=0&id=52c6a840&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52c6a840",
  null
  
)

export default component.exports